import { useEffect, useState } from "react";
import Countdown from "react-countdown-now";
import "../../../../styles.css";

export const Timer = ({
  timeLeftInSeconds,
  formatMinute = "m",
  formatSecond = "ss",
  onTimeout = () => { },
}) => {
  const [timeoutDate, setTimeoutDate] = useState(
    Date.now() + timeLeftInSeconds * 1000
  );

  useEffect(() => {
    setTimeoutDate(Date.now() + timeLeftInSeconds * 1000);
  }, [timeLeftInSeconds]);

  const CountdownStringComponent = (minutes, seconds) => {
    return (
      <span className="scaptcha-countdown-timer">
        {`${String(minutes).padStart(formatMinute.length, "0")}:${String(
          seconds
        ).padStart(formatSecond.length, "0")}`}
      </span>
    );
  };

  return (
    <Countdown
      key={timeoutDate}
      date={timeoutDate}
      onComplete={onTimeout}
      renderer={(props) =>
        CountdownStringComponent(props.minutes, props.seconds)
      }
    />
  );
};
