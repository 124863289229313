import React from "react";

const ArrowInactiveIcon = () => (
  <div className="scaptcha-icon-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#CACACA"
        fillRule="evenodd"
        d="M2.403 9.172a.833.833 0 00.097 1.661h12.987L9.411 16.91l-.07.079a.833.833 0 001.248 1.1l7.5-7.5.07-.079a.833.833 0 00-.07-1.1l-7.5-7.5-.078-.069a.833.833 0 00-1.1.07l-.07.078a.833.833 0 00.07 1.1l6.076 6.077H2.5l-.097.006z"
        clipRule="evenodd"
      ></path>
    </svg>
  </div>
);

export default ArrowInactiveIcon;
