import React, { useEffect, useState } from "react";
import iconblock from "./icons/svg/icon-block.svg";
import {
  ArrowIcon,
  SuccessIcon,
  FailureIcon,
  ArrowInactiveIcon,
} from "./icons";

const imageDataUrl = (image) => `data:image/png;base64,${image}`;

const slider = {
  default: {
    track: "scaptcha-card-slider-track-default",
    control: "scaptcha-card-slider-control-default",
    icon: <ArrowIcon />,
  },
  active: {
    track: "scaptcha-card-slider-track-active",
    control: "scaptcha-card-slider-control-active",
    icon: <ArrowIcon />,
  },
  success: {
    track: "scaptcha-card-slider-track-success",
    control: "scaptcha-card-slider-control-success",
    icon: <SuccessIcon />,
  },
  failure: {
    track: "scaptcha-card-slider-track-failure",
    control: "scaptcha-card-slider-control-failure",
    icon: <FailureIcon />,
  },
  blocked: {
    track: "scaptcha-card-slider-track-blocked",
    control: "scaptcha-card-slider-control-blocked",
    icon: <ArrowInactiveIcon />,
  },
};

const Challenge = ({
  text,
  captcha,
  loaded,
  failed,
  blocked,
  reset,
  status,
  completeCaptcha,
}) => {
  const { useState } = React;
  const [sliderVariant, setSliderVariant] = useState(slider.default);
  const [solving, setSolving] = useState(false);
  const [submittedResponse, setSubmittedResponse] = useState(false);
  const [origin, setOrigin] = useState({
    x: 0,
    y: 0,
  });
  const [trail, setTrail] = useState({
    x: [0],
    y: [0],
  });

  const scaleSliderPosition = (x) => 5 + 0.86 * x;

  useEffect(() => {
    setSliderVariant(slider.default);
    setSolving(false);
    setSubmittedResponse(false);
    setOrigin({ x: 0, y: 0 });
    setTrail({ x: [0], y: [0] });
  }, [reset]);

  useEffect(() => {
    if (status != "") {
      switch (status) {
        case "init":
          setSliderVariant(slider.default);
          break;
        case "success":
          setSliderVariant(slider.success);
          break;
        case "failure":
          setSliderVariant(slider.failure);
          break;
        case "blocked":
          setSliderVariant(slider.blocked);
          break;
        default:
          setSliderVariant(slider.default);
          break;
      }
    }
  }, [status]);

  const handleStart = (e) => {
    if (submittedResponse && blocked && !loaded) return;

    if (!blocked && loaded) {
      if (origin.x === 0 && origin.y === 0) {
        window.RecaptchaFlutterChannel?.postMessage(
          JSON.stringify({ action: "startSolving", data: null })
        );

        setOrigin({
          x: e.clientX || e.touches[0].clientX,
          y: e.clientY || e.touches[0].clientY,
        });
        setSolving(true);
        setSliderVariant(slider.active);
      }
    }
  };

  const handleMove = (e) => {
    if (!solving || submittedResponse || blocked || !loaded) return;

    const move = {
      x: (e.clientX || e.touches[0].clientX) - origin.x,
      y: (e.clientY || e.touches[0].clientY) - origin.y,
    };

    if (move.x > 246 || move.x < 0) return;

    setTrail({
      x: trail.x.concat([move.x]),
      y: trail.y.concat([move.y]),
    });
  };

  const handleEnd = () => {
    if (!solving || submittedResponse || blocked || !loaded) return;

    setSubmittedResponse(true);
    completeCaptcha(scaleSliderPosition(trail.x[trail.x.length - 1]), trail);
  };

  return (
    <div
      className="scaptcha-card-element"
      draggable="false"
      onMouseMove={handleMove}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
      onMouseUp={handleEnd}
      onMouseLeave={handleEnd}
    >
      <div
        className="scaptcha-card-background scaptcha-card-element"
        style={{
          backgroundImage: captcha["captcha_image"]
            ? `url('${imageDataUrl(captcha["captcha_image"])}')`
            : blocked
            ? `url('${iconblock}')`
            : "",
          backgroundColor: !blocked ? "#ffffff" : "#f0f0f0",
          border: "0.7px solid #d8d8d8",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderRadius: "2px",
        }}
      />
      <div
        className="scaptcha-card-slider-puzzle scaptcha-card-element"
        style={{
          backgroundImage:
            loaded && captcha["captcha_slider"]
              ? `url('${imageDataUrl(captcha["captcha_slider"])}')`
              : ``,
          left: `${scaleSliderPosition(trail.x[trail.x.length - 1])}px`,
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      />
      <div className="scaptcha-card-slider-container scaptcha-card-element">
        <div
          className="scaptcha-card-slider-track scaptcha-card-element"
          style={{
            backgroundColor: !blocked ? "#fbfbfb" : "#f4f4f4",
            border: "0.7px solid #d8d8d8",
            borderRadius: "2px",
          }}
        />
        <div
          className="scaptcha-card-slider-label scaptcha-card-element"
          style={{ opacity: solving ? 0 : 1 }}
        >
          <span style={{ color: !blocked ? "#000000" : "#a2a2a2" }}>
            {!loaded
              ? text.loading
              : blocked
              ? text.blocked
              : failed
              ? text.failed
              : text.challenge}
          </span>
        </div>
        <div
          className={`scaptcha-card-slider-mask ${sliderVariant.track} scaptcha-card-element`}
          style={{ width: `${trail.x[trail.x.length - 1] + 42}px` }}
        />
        <div
          className="scaptcha-card-slider-container scaptcha-card-element"
          draggable="false"
        />
        <div
          className={`scaptcha-card-slider-control ${sliderVariant.control} scaptcha-card-element`}
          style={{ left: `${trail.x[trail.x.length - 1]}px` }}
          onMouseDown={handleStart}
          onTouchStart={handleStart}
        >
          {sliderVariant.icon}
        </div>
      </div>
    </div>
  );
};

export default Challenge;
