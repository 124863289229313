import React from 'react';
import "./loading.css";

const Loading = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const flowCode = queryParameters.get("flowCode");
  let description = '';
  switch (flowCode) {
    case 'BUYRESERVE':
      description ='กำลังยืนยันการซื้อ-จองสลากฯ';
      break;
    case 'DURING_ROUND':
      description = 'กำลังยืนยันการชำระเงินค่าสลากฯ';
    default:
      break;
  }

  return (
    <div className='ball-loading'>

      <div className="image-container">
        <div className='image-body' >
          <div>
            <img
              src="/loading-bounce.gif"
            />
            <p className="wording">รอสักครู่...<br />{description}</p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
