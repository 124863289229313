import React from "react";

const FailureIcon = () => (
  <div className="scaptcha-icon-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#212121"
        fillRule="evenodd"
        d="M17.256 2.744a.833.833 0 00-1.179 0L10 8.821 3.923 2.744l-.079-.07a.833.833 0 00-1.1 1.249L8.821 10l-6.077 6.077-.07.079a.833.833 0 001.249 1.1L10 11.178l6.077 6.078.079.07a.833.833 0 001.1-1.249L11.178 10l6.078-6.077.07-.079a.833.833 0 00-.07-1.1z"
        clipRule="evenodd"
      ></path>
    </svg>
  </div>
);

export default FailureIcon;
