import "./styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SliderCaptcha from "./react/src/pages/Captcha/slider-captcha";
import Loading from "./react/src/pages/Loading/loading";
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="captcha" element={<SliderCaptcha />} />
        <Route path="loading" element={<Loading />} />
      </Routes>
    </BrowserRouter>
  );
}
