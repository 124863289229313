import React from 'react';

const SuccessIcon = () => (
  <div className="scaptcha-icon-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#212121"
        d="M16.903 4.007a.822.822 0 011.178-.016.863.863 0 01.084 1.121l-.068.081-10.313 10.8a.822.822 0 01-1.115.073l-.079-.073-4.687-4.909a.862.862 0 01.015-1.202.821.821 0 011.101-.056l.078.072 4.09 4.283 9.716-10.174z"
      ></path>
    </svg>
  </div>
);

export default SuccessIcon;
